@import url("https://use.typekit.net/qug0ail.css");

:root {
  --font-main: "sofia-pro";
  --font-weight-main: 'light';

  --color-black: #2d3548;
  --color-grey: #70829B;
  --color-grey-light: #ACB5C1;
  --color-white: #ffffff;

  --color-primary: #264e85;
  --color-primary-light: #5683c1;
  --color-primary-lightest: #F3F6FB;

  --color-text-black: var(--color-black);
  --color-text-grey: var(--color-grey);
  --color-text-white: var(--color-primary-lightest);

  --color-bg-dark: var(--color-primary);
  --color-bg-light: var(--color-primary-lightest);

  --color-dropshadow: rgb(20 66 130 / 15%);
  --color-dropshadow-svg: rgb(20 66 130 / 25%);

  --color-backdrop: rgba(12, 33, 62, 0.2);
}
