html {
  font-size: 10px;
}

body {
  font-size: 1rem;
  font-weight: var(--font-weight-main);
  margin: 0;
  font-family: var(--font-main), -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

* {
  box-sizing: border-box;
}

body {
  color: var(--color-text-black);
}



#root .button, .button {
  text-align: center;
  background: var(--color-primary);
  font-family: phoreuscherokee, sans-serif;
  color: var(--color-text-white);
  font-size: 1.1rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color .2s ease;
  padding: 1rem 1rem;
  border-radius: 4rem;
}

#root .button:hover, .button:hover {
  background-color: var(--color-primary-light);
}
